<template>
  <v-row>
    <!-- <v-col cols="6" class="px-1">
      <v-switch
        :input-value="newFilter"
        @change="handleNewFilter"
        class="mt-0 py-2 promo-checkbox-wrap"
        hide-details
      >
        <template v-slot:label>
          <span> {{ $t("filter.new") }}</span>
        </template></v-switch
      >
    </v-col> -->
    <v-col cols="auto" class="px-1">
      <v-switch
        :input-value="promoFilter"
        @change="handlePromoFilter"
        class="mt-0 py-2 promo-checkbox-wrap"
        hide-details
      >
        <template v-slot:label>
          <span class="font-weight-bold  primary--text text-capitalize">
            {{ $t("filter.promo") }}</span
          >
        </template></v-switch
      >
    </v-col>
    <v-col cols="auto" class="px-1">
      <v-switch
        :input-value="coopFilter"
        @change="handleCoopFilter"
        class="mt-0 py-2 promo-checkbox-wrap"
        hide-details
      >
        <template v-slot:label>
          <span class="default--text font-weight-bold text-capitalize">
            {{ $t("filter.coopProducts") }}</span
          >
        </template></v-switch
      >
    </v-col>
    <!-- <v-col cols="auto" class="px-1">
      <v-switch
        :input-value="glutenFilter"
        @change="handleGlutenFilter"
        class="mt-0 py-2 promo-checkbox-wrap"
        hide-details
      >
        <template v-slot:label>
          <span class="default--text font-weight-bold text-capitalize">
            {{ $t("filter.glutinFree") }}</span
          >
        </template></v-switch
      >
    </v-col> -->
  </v-row>
</template>

<script>
export default {
  name: "ProductPromoFilter",
  props: ["promoFilter", "coopFilter", "newFilter"],
  methods: {
    handlePromoFilter(promo) {
      this.$emit("handlePromoFilter", promo);
    },
    handleCoopFilter(coop) {
      //coop = ["1001", "111"];
      console.log("bbbbb", coop);
      this.$emit("handleCoopFilter", coop);
    },
    // handleGlutenFilter(gluten) {
    //   this.$emit("handleGlutenFilter", gluten);
    // },
    handleNewFilter(onlyNew) {
      this.$emit("handleNewFilter", onlyNew);
    }
  }
};
</script>

<style scoped lang="scss">
.promo-checkbox-wrap {
  &::v-deep .v-input__slot {
    //border: 1px solid #d5d5d5;
    padding: 1px 6px 1px 12px;
    max-width: fit-content;
    border-radius: 8px;
  }
  &::v-deep .v-chip {
    height: 30px;
    font-weight: 600;
    cursor: pointer;
  }
}
</style>
