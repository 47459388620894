<template>
  <!-- :label="$vuetify.breakpoint.smAndDown ? $t('products.orderBy') : ''" -->
  <div class="w-100 product-sort-filters align-items-center">
    <v-select
      class="rounded-pill"
      :items="sortOptions"
      :value="sortFilter"
      @change="handleSortFilter"
      :label="$t('products.orderBy')"
      outlined
      hide-details
    >
    </v-select>
  </div>
</template>

<script>
export default {
  name: "ProductSortFilter",
  data() {
    return {
      sortOptions: [
        {
          value: "rank",
          text: this.$t("filter.sort.relevance"),
          icon: "ion-arrow-up-c"
        },
        {
          value: "alf_asc",
          text: this.$t("filter.sort.alphCsc"),
          icon: "icon-arrow-up"
        },
        {
          value: "alf_des",
          text: this.$t("filter.sort.alphDsc"),
          icon: "icon-arrow-down"
        },
        {
          value: "pri_asc",
          text: this.$t("filter.sort.priceCsc"),
          icon: "icon-arrow-down"
        },
        {
          value: "pri_des",
          text: this.$t("filter.sort.priceDsc"),
          icon: "ion-arrow-down-c"
        }
      ]
    };
  },
  props: { sortFilter: { type: String } },
  methods: {
    handleSortFilter(sort) {
      this.$emit("handleSortFilter", sort);
    }
  }
};
</script>

<style scoped lang="scss">
.product-sort-filters {
  .v-input__slot {
    min-height: 50px !important;
  }
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    .v-input__slot {
      max-width: 200px !important;
    }
  }
  .v-text-field--outlined fieldset {
    color: #343f59 !important;
    border-radius: 9999px;
    border: 1px solid #343f59;
  }
}
</style>
